<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft :paragraph="para" :member="mem" :button2="btn" />
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Global NDA</h4>
            </div>

            <div class="auth-page-right-content">
              <div class="heading">
                <ThemeButton />
                <h6>Hello!</h6>
                <p v-if="errorMessage != null" class="error">
                  {{ errorMessage }}
                </p>
                <p v-else>Sign Up to Get Started</p>
              </div>
              <form @keyup.enter="register(0)">
                <div class="form-group">
                  <div class="form-icon">
                    <img src="../../assets/images/name-icon.png" alt="" />
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="first_name"
                    placeholder="First Name"
                    autocomplete="off"
                    v-model.trim="$v.details.first_name.$model"
                    :class="{
                      'is-invalid': $v.details.first_name.$error,
                      'is-valid': !$v.details.first_name.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.first_name.required"
                      >First name is required</span
                    >
                    <span v-if="!$v.details.first_name.minLength"
                      >First name must have at least
                      {{ $v.details.first_name.$params.minLength.min }}
                      characters</span
                    >
                    <span v-if="!$v.details.first_name.maxLength"
                      >First name must have at most
                      {{ $v.details.first_name.$params.maxLength.max }}
                      characters</span
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-icon">
                    <img src="../../assets/images/name-icon.png" alt="" />
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="last_name"
                    placeholder="Last Name"
                    autocomplete="off"
                    v-model.trim="$v.details.last_name.$model"
                    :class="{
                      'is-invalid': $v.details.last_name.$error,
                      'is-valid': !$v.details.last_name.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.last_name.required"
                      >Last name is required</span
                    >
                    <span v-if="!$v.details.last_name.minLength"
                      >Last name must have at least
                      {{ $v.details.last_name.$params.minLength.min }}
                      characters</span
                    >
                    <span v-if="!$v.details.last_name.maxLength"
                      >Last name must have at most
                      {{ $v.details.last_name.$params.maxLength.max }}
                      characters</span
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-icon">
                    <img src="../../assets/images/email-icon.png" alt="" />
                  </div>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Business Email"
                    autocomplete="off"
                    v-model.trim="$v.details.email.$model"
                    :class="{
                      'is-invalid': $v.details.email.$error,
                      'is-valid': !$v.details.email.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.email.required"
                      >Business email is required</span
                    >
                    <span v-if="!$v.details.email.email"
                      >Business email must be valid
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-icon">
                    <img src="../../assets/images/password-icon.png" alt="" />
                  </div>
                  <input
                    :type="type"
                    class="form-control"
                    id="password"
                    placeholder="Password"
                    autocomplete="off"
                    v-model.trim="$v.details.password.$model"
                    :class="{
                      'is-invalid': $v.details.password.$error,
                      'is-valid': !$v.details.password.$invalid,
                    }"
                  />
                  <i
                    v-html="btnText"
                    id="togglePassword"
                    @click="showPassword"
                  ></i>
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.password.required"
                      >Password is required</span
                    >
                    <span v-if="!$v.details.password.minLength"
                      >Password must have at least
                      {{ $v.details.password.$params.minLength.min }}
                      letters</span
                    >
                    <span v-if="!$v.details.password.maxLength"
                      >Password must have at most
                      {{ $v.details.password.$params.maxLength.max }}
                      letters</span
                    >
                  </div>
                </div>
                <button
                  type="button"
                  class="auth-btn"
                  @click="register(0)"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Register</span>
                </button>
                <!-- Disabled button for recaptcha -->
                <!-- <button
                  type="button"
                  class="dis-auth-btn"
                  title="Button is disabled, to enable first verify reCAPTCHA"
                  >Register</button> -->
              </form>
              <!-- <vue-recaptcha
                class="mt-4 d-flex align-items-center justify-content-center"
                @verify="checkRecaptcha"
                :sitekey="VUE_APP_RECAPTA_KEY"
              ></vue-recaptcha> -->
            </div>

            <div class="account d-md-none">
              <router-link to="/login" class="forgot-password"
                >Sign In</router-link
              >
            </div>

            <div class="circle-one d-block d-md-none">
              <img src="../../assets/images/circle1.png" />
            </div>
            <div class="circle-two d-block d-md-none">
              <img src="../../assets/images/circle2.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VueRecaptcha } from "vue-recaptcha";
import ThemeButton from "../../components/Theme/ThemeButton.vue";
import { mapGetters, mapActions } from "vuex";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import AuthLeft from "../../components/authleft/AuthLeft.vue";

export default {
  name: "Register",

  components: {
    ThemeButton,
    AuthLeft,
    // VueRecaptcha,
  },

  data: function() {
    return {
      isLoadingArray: [],
      // VUE_APP_RECAPTA_KEY: this.$recaptchaKey,
      details: {
        first_name: "",
        last_name: "",
        email: "",
        password: ""
      },
      type: "password",
      btnText: '<i class="fa fa-eye-slash"></i>',
      errorMessage: null,
      para: "Simplify your NDA today. Sign up to enjoy the perks!",
      mem: "Already a member?",
      btn: "Sign In",
    };
  },

  validations: {
    details: {
      first_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
      },
      last_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(15),
      },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendRegisterRequest"]),

    register: function(index) {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.sendRegisterRequest(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          this.$set(this.isLoadingArray, index, false);

           this.$swal({
              title: 'Verification link sent to ' +this.details.email+ '. Please follow instructions to verify your account.',
              icon: "info",
              showCancelButton: false,
            });
          setTimeout(() => {
            this.$router.push({ name: "VerificationEmail" });
          }, 500);
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },

    showPassword: function() {
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "<i class='fa fa-eye'></i>";
      } else {
        this.type = "password";
        this.btnText = "<i class='fa fa-eye-slash'></i>";
      }
    },

    // checkRecaptcha: function() {
    //   document.querySelector(".auth-btn").style.display = "block";
    //   document.querySelector(".dis-auth-btn").style.display = "none";
    // },
  },
};
</script>

<style scoped>
/* .auth-btn {
  display: none;
} */
.dis-auth-btn {
  display: none;
}
</style>
