
<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft :paragraph="para" :button2="btn" :button5="btn1"/>
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Global NDA</h4>
            </div>

            <div class="auth-page-right-content text-center">
              <ThemeButton class="d-block" />
              <p>Let's add your payment details</p>

              <CardDetailModal v-if="showCardDetailModal"/>
              <button
                type="button"
                class="auth-btn mt-2"
                @click="payment()"
              >
                <span>Add payment</span>
              </button>
            
            </div>
            <div class="circle-one d-block d-md-none">
              <img src="../../assets/images/circle1.png" />
            </div>
            <div class="circle-two d-block d-md-none">
              <img src="../../assets/images/circle2.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeButton from "../../components/Enterprise/Theme/ThemeButton.vue";
import AuthLeft from "../../components/Enterprise/authleft/AuthLeft.vue";
import CardDetailModal from '../../components/ndaform/CardDetailModal.vue';

export default {
  name: "EnterprisePackage",


components: {
  ThemeButton,
  AuthLeft,
  CardDetailModal,
},

data: function() {
  return {
    para: "Let’s add your payment details",
    showCardDetailModal: true,
    btn: "Logout",
    btn1: "Logout",

  };
},


mounted() {
      const modalTitle= "Card details";
    const Card = "card";
    this.$root.$emit("openCardDetailModal", Card, modalTitle );
    },

methods: {
  payment(){
    const modalTitle= "Card details";
    const Card = "card";
    this.$root.$emit("openCardDetailModal", Card, modalTitle );
  }
},
};
</script>
